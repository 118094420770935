import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Page = () => {
  const metadata = {
    title: "Our Team - Bartlett & Grippe",
    description:
      "Ron Houde is a trial and appellate lawyer practicing insurance defense, personal injury, and general litigation. He practices throughout Connecticut in state, federal, and tribal courts.",
    heroH1: "Our Team",
    heroImage: "photo-bg-staff.jpg",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16 mt-16">
            <h2 className="uppercase">Meet Our Esteemed Team of Attorneys</h2>
            <p className="mb-8">
                At Bartlett & Grippe, we understand that the cornerstone of any great law firm is its team. Our attorneys are more than just legal professionals; they are dedicated advocates, seasoned negotiators, and passionate defenders of justice. With a rich blend of experience, expertise, and empathy, our team stands ready to guide you through the complexities of the legal system. Let's introduce you to the distinguished members of our legal team.
            </p>

            <div className="flex flex-wrap">


              <div class="rounded-2xl bg-base-300 shadow-lg flex mb-16 w-full sm:max-w-max mr-0 sm:mr-4">
                <figure style={{ height: "150px", width: "150px" }}>
                  <StaticImage
                    className="rounded-l-2xl"
                    height={"150"}
                    quality="100"
                    src="../../images/photo-frank-bartlett.jpg"
                    formats={["auto", "webp", "avif"]}
                    alt="Photo of Attorney Frank Bartlett Jr"
                    imgStyle={{
                      borderTopLeftRadius: "1rem",
                      borderBottomLeftRadius: "1rem",
                    }}
                  />
                </figure>
                <div class="px-1 pb-2 text-gray-900">
                  <p className="text-xl font-bold">Frank C. Bartlett Jr.</p>
                  <p className="">Partner</p>
                  <p className="">
                    <a href="tel:+1-203-439-7717">203-439-7717</a> <strong>phone</strong>
                  </p>
                  <p>
                    203-439-7730 <strong>fax</strong>
                  </p>
                  <p>
                    <Link to="/firm-overview/frank-c-bartlett-jr/">Full Bio</Link>
                  </p>
                </div>
              </div>

              <div class="rounded-2xl bg-base-300 shadow-lg flex mb-16 w-full sm:max-w-max mr-0 sm:mr-4">
                <figure style={{ height: "150px", width: "150px" }}>
                  <StaticImage
                    className="rounded-l-2xl"
                    height={"150"}
                    quality="100"
                    src="../../images/photo-joseph-grippe.jpg"
                    formats={["auto", "webp", "avif"]}
                    alt="Photo of Joseph R. Grippe"
                    imgStyle={{
                      borderTopLeftRadius: "1rem",
                      borderBottomLeftRadius: "1rem",
                    }}
                  />
                </figure>
                <div class="px-1 pb-2 text-gray-900">
                  <p className="text-xl font-bold">Joseph R. Grippe</p>
                  <p className="">Partner</p>
                  <p className="">
                    <a href="tel:+1-203-272-1157">203-272-1157</a> <strong>phone</strong>
                  </p>
                  <p className="">
                    203-250-1835 <strong>fax</strong>
                  </p>
                  <p>
                    <Link to="/firm-overview/joseph-r-grippe/">Full Bio</Link>
                  </p>
                </div>
              </div>


              <div class="rounded-2xl bg-base-300 shadow-lg flex mb-16 w-full sm:max-w-max mr-0 sm:mr-4">
                <figure style={{ height: "150px", width: "150px" }}>
                  <StaticImage
                    className="rounded-l-2xl"
                    height={"150"}
                    quality="100"
                    src="../../images/atty_holmes.jpg"
                    formats={["auto", "webp", "avif"]}
                    alt="Photo of Grayson Colt Holmes"
                    imgStyle={{
                      borderTopLeftRadius: "1rem",
                      borderBottomLeftRadius: "1rem",
                    }}
                  />
                </figure>
                <div class="px-1 pb-2 text-gray-900">
                  <p className="text-xl font-bold">Grayson Colt Holmes</p>
                  <p className="">Partner</p>
                  <p className="">
                    <a href="tel:+1-203-272-1157">203-272-1157</a> <strong>phone</strong>
                  </p>
                  <p className="">
                    203-250-1835 <strong>fax</strong>
                  </p>
                  <p>
                    <Link to="/firm-overview/grayson-colt-holmes/">
                      Full Bio
                    </Link>
                  </p>
                </div>
              </div>



              <div class="rounded-2xl bg-base-300 shadow-lg flex mb-16 w-full sm:max-w-max mr-0 sm:mr-4">
                <figure style={{ height: "150px", width: "150px" }}>
                  <StaticImage
                    className="rounded-l-2xl"
                    height={"150"}
                    quality="100"
                    src="../../images/atty_Karen.jpg"
                    formats={["auto", "webp", "avif"]}
                    alt="Photo of Karen J.S. Gallagher"
                    imgStyle={{
                      borderTopLeftRadius: "1rem",
                      borderBottomLeftRadius: "1rem",
                    }}
                  />
                </figure>
                <div class="px-1 pb-2 text-gray-900">
                  <p className="text-xl font-bold">Karen J.S. Gallagher</p>
                  <p className="">Of Counsel</p>
                  <p className="">
                    <a href="tel:+1-203-272-1157">203-272-1157</a> <strong>phone</strong>
                  </p>
                  <p className="">
                    203-250-1835 <strong>fax</strong>
                  </p>
                  <p>
                    <Link to="/firm-overview/karen-j-s-gallagher/">
                      Full Bio
                    </Link>
                  </p>
                </div>
              </div>

              <div class="rounded-2xl bg-base-300 shadow-lg flex mb-16 w-full sm:max-w-max mr-0 sm:mr-4">
                <figure style={{ height: "150px", width: "150px" }}>
                  <StaticImage
                    className="rounded-l-2xl"
                    height={"150"}
                    quality="100"
                    src="../../images/atty_gamsby.jpg"
                    formats={["auto", "webp", "avif"]}
                    alt="Photo of Jason K. Gamsby"
                    imgStyle={{
                      borderTopLeftRadius: "1rem",
                      borderBottomLeftRadius: "1rem",
                    }}
                  />
                </figure>
                <div class="px-1 pb-2 text-gray-900">
                  <p className="text-xl font-bold">Jason K. Gamsby</p>
                  <p className="">Attorney</p>
                  <p className="">
                    <a href="tel:+1-203-272-1157">203-272-1157</a> <strong>phone</strong>
                  </p>
                  <p className="">
                    203-250-1835 <strong>fax</strong>
                  </p>
                  <p>
                    <Link to="/firm-overview/jason-gamsby/">Full Bio</Link>
                  </p>
                </div>
              </div>


              <div class="rounded-2xl bg-base-300 shadow-lg flex mb-16 w-full sm:max-w-max mr-0 sm:mr-4">
                <figure style={{ height: "150px", width: "150px" }}>
                  <StaticImage
                    className="rounded-l-2xl"
                    height={"150"}
                    quality="100"
                    src="../../images/atty_grant.jpg"
                    formats={["auto", "webp", "avif"]}
                    alt="Photo of Steven J. Grant"
                    imgStyle={{
                      borderTopLeftRadius: "1rem",
                      borderBottomLeftRadius: "1rem",
                    }}
                  />
                </figure>
                <div class="px-1 pb-2 text-gray-900">
                  <p className="text-xl font-bold">Steven J. Grant</p>
                  <p className="">Associate</p>
                  <p className="">
                    <a href="tel:+1-203-272-1157">203-272-1157</a> <strong>phone</strong>
                  </p>
                  <p className="">
                    203-250-1835 <strong>fax</strong>
                  </p>
                  <p>
                    <Link to="/firm-overview/steven-j-grant/">Full Bio</Link>
                  </p>
                </div>
              </div>



              <div class="rounded-2xl bg-base-300 shadow-lg flex mb-16 w-full sm:max-w-max mr-0 sm:mr-4">
                <figure style={{ height: "150px", width: "150px" }}>
                  <StaticImage
                    className="rounded-l-2xl"
                    height={"150"}
                    quality="100"
                    src="../../images/atty_houde.jpg"
                    formats={["auto", "webp", "avif"]}
                    alt="Photo of Ron Houde"
                    imgStyle={{
                      borderTopLeftRadius: "1rem",
                      borderBottomLeftRadius: "1rem",
                    }}
                  />
                </figure>
                <div class="px-1 pb-2 text-gray-900">
                  <p className="text-xl font-bold">Ron Houde</p>
                  <p className="">Associate</p>
                  <p className="">
                    <a href="tel:+1-203-272-1157">203-272-1157</a> <strong>phone</strong>
                  </p>
                  <p className="">
                    203-250-1835 <strong>fax</strong>
                  </p>
                  <p>
                    <Link to="/firm-overview/ron-houde/">Full Bio</Link>
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
